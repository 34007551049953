import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import wowImage from "./assets/icons/wow_header_icon.png";
import leftImage from "./assets/icons/header_icon.png";
import "./App.css";
import { KlassList } from "./components/KlassList";

const Druid = lazy(() => import("./trees/Druid"));
const Hunter = lazy(() => import("./trees/Hunter"));
const Mage = lazy(() => import("./trees/Mage"));
const Paladin = lazy(() => import("./trees/Paladin"));
const Priest = lazy(() => import("./trees/Priest"));
const Rogue = lazy(() => import("./trees/Rogue"));
const Shaman = lazy(() => import("./trees/Shaman"));
const Warlock = lazy(() => import("./trees/Warlock"));
const Warrior = lazy(() => import("./trees/Warrior"));

export const App: React.FC = () => {
  return (
    <div className="App">
      <div
        style={{
          textAlign: "center",
          marginTop: 8,
          height: 50,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <a href="https://sodwow.com">
          <img
            src={leftImage}
            width={50}
            style={{ position: "absolute", left: 20, top: 20 }}
            alt="Season Of Discovery (SoD) Talent Calculator Patch 3"
          />
        </a>
        <span style={{ color: "white", fontSize: 26 }}>
          Season of Discovery Talent Calculator
        </span>
      </div>
      <div style={{ textAlign: "center", marginBottom: 8 }}>
        <span style={{ color: "grey" }}>
          Select your class to use your preferred talent calculator
        </span>
      </div>
      <KlassList />
      <Suspense fallback={null}>
        <Route path="/druid" component={Druid} />
        <Route path="/hunter" component={Hunter} />
        <Route path="/mage" component={Mage} />
        <Route path="/paladin" component={Paladin} />
        <Route path="/priest" component={Priest} />
        <Route path="/rogue" component={Rogue} />
        <Route path="/shaman" component={Shaman} />
        <Route path="/warlock" component={Warlock} />
        <Route path="/warrior" component={Warrior} />
      </Suspense>
    </div>
  );
};
